
import React, { useState, useEffect, useContext } from 'react';
import { Icon, Button } from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import moment from 'moment'

import AppContext from '../context/app'
import localStorage from '../services/localstorage'
import userService from '../services/user';


export default function Video(props) {
  const context = useContext(AppContext)
  const videoWatchedKey = `videoWatched-${context.trainingPage.videoId}`
  //was the video completed in this session
  const [videoComplete, setVideoComplete] = useState(false)
  //was the video watched in this session or prior
  const [videoWatched, setVideoWatched] = useState(false)
  const [lastSavedvideoWatchedSeconds, setLastSavedvideoWatchedSeconds] = useState(0)
  const [timeWatchedSeconds, setTimeWatchedSeconds] = useState(0)
  const [videoId, setVideoId] = useState()
  const [videoStart, setVideoStart] = useState(false)
  const [play, setPlay] = useState(true)


  useEffect( () => {
    if (!context.trainingPage) {
        props.history.push({pathname: '/home'});
        return
    }
    const localVideoWatchedTime = new localStorage().get(videoWatchedKey)
    if (localVideoWatchedTime){
      setVideoStart(localVideoWatchedTime)
      setLastSavedvideoWatchedSeconds(localVideoWatchedTime)
    }

    const localVideoWatched = context.user.videoComplete.some( vc => vc.sectionVideoId === context.trainingPage.sectionVideoId)
    if (localVideoWatched)
      setVideoWatched(true)
    setVideoId(context.trainingPage.videoId)
    // to get controls immediately
    // setVideoWatched(true)
  },[context.trainingPage.videoId])

  let saveVideoWatched = async () => {
    setVideoWatched(true)
    new localStorage().remove(videoWatchedKey) //clear so you start from the beginning
    await new userService().sectionVideoComplete(context.trainingPage.sectionVideoId) //mark complete
  }

  let handleVideoProgress = (watchedTime) => {
    //save every 60 seconds your progress
    setTimeWatchedSeconds(watchedTime.seconds)
    if (watchedTime.seconds >= lastSavedvideoWatchedSeconds+60){
      //Save that the video was watched
      new localStorage().set(videoWatchedKey,watchedTime.seconds,60 * 60 * 24 * 180) //6 months
      setLastSavedvideoWatchedSeconds(watchedTime.seconds)

       //save if you are really close to the end of the video (5 min)
      if (!videoWatched && watchedTime.seconds >= context.trainingPage.videoLengthSeconds - (60 * 5))
        saveVideoWatched()
    }
  }

  let handleVideoEnd = async () => {
    //Save that the video was watched
    saveVideoWatched()
    setVideoComplete(true)
  }
 
  let handleBack = () => {
    context.back()    
    setTimeout(()=> { window.scroll(0,0)}, 200 )
  }

  //handle Next
  let handleNext = async (event) => {
    let nextLocationIndex = context.locationIndex+1
    await context.next()
    //if going past the last page
    if (nextLocationIndex >= context.training.length)
      props.history.push(`/thankyou`);
    window.scroll(0,0)
  }

  let formatSeconds = (seconds) => {
    // Create a moment duration object
    var duration = moment.duration(seconds, 'seconds');

    // Get the hours and minutes
    var hours = Math.floor(duration.asHours());
    var minutes = duration.minutes();

    // Return the formatted string
    return `${hours ? `${hours} hours and ` : ''}${minutes} minutes`;
}


  return (
    <div id='video'>
        { context.trainingPage && videoId ?
        <section>
            <div className="flex-row video-content">
              <div className="flex-col video-text-container">
                  { !videoComplete ?
                    <div>
                      <Vimeo className="vimeo-video" video={ videoId } controls={ videoWatched } volume={1} start={videoStart || 0} paused={!play} autoplay={true} onEnd={handleVideoEnd} onTimeUpdate={ handleVideoProgress } />
                      
                      
                      <div className="flex-row actions">
                        <div className="flex-col">
                          { !videoWatched ? 
                          <section>
                          { formatSeconds(context.trainingPage.videoLengthSeconds - timeWatchedSeconds) } remaining
                          { play ?
                            <Button className="pause-play" onClick={() => setPlay(false)} variant="outlined"><Icon className="fa fa-pause" />Pause</Button>
                          :
                            <Button className="pause-play" onClick={() => setPlay(true)} variant="outlined"><Icon className="fa fa-play" />Play</Button>
                          }
                          </section>
                          : null }
                        </div>
                      </div>
                    </div>
                  : 
                    <div className="video-complete-text">You have completed the video. Please click Next and answer some questions. <Button onClick={() => setVideoComplete(false) } variant="outlined"><Icon className="fa fa-rotate-left" /> Replay</Button></div>
                  }
              </div>
              </div>

            <div className="flex-row actions">
              <div className="flex-col">
                <Button className="back" onClick={handleBack} variant="outlined"><Icon className="fa fa-arrow-left" /> Back</Button>
              </div>
              <div className="flex-col">
                <Button disabled={!videoWatched} onClick={handleNext} variant="outlined"><Icon className="fa fa-arrow-right" /> Next</Button>
              </div>
            </div>
            
        </section>
        :
        null
        }
    </div>
  )
}

